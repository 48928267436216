import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import MasterLayout from "./layouts/admin/MasterLayout";
import Profile from "./components/admin/Profile";
import Dashboard from "./components/admin/Dashboard";
import Login from "./components/frontend/auth/Login";
import PrivateRoutes from "./components/frontend/auth/PrivateRoutes";
import CRMI from "./components/admin/CrmInstance";

import axios from "axios";
import Orders from "./components/admin/Orders";
import AddProds from "./components/admin/AddProds";
import ListProducts from "./components/admin/Viewprods";
import Adduser from "./components/admin/Adduser";
import NotFound from "./components/admin/NotFound";
import AccessDenied from "./components/admin/AccessDenied";
import Listusers from "./components/admin/Listusers";
import Category from "./components/admin/Category";
import AutoProductMap from "./components/admin/AutoProductMap";
import AddCheckoutFunnel from "./components/admin/AddCheckoutFunnel";
import CheckoutFunnelList from "./components/admin/CheckoutFunnelList";
import EditCheckoutFunnel from "./components/admin/EditCheckoutFunnel";
import AcvgummiesMobileTemplate from "./components/LanderTemplate/AcvgummiesMobileTemplate";
import MasterCheckout from "./components/admin/MasterCheckout";
import AssignementTemplate from "./components/admin/AssignementTemplate";
import AssignmentLander from "./components/admin/AssignmentLander";
import AssignmentUpsell from "./components/admin/AssignmentUpsell";
import Confirmation from "./components/admin/Confirmation";
import Funnelflow from "./components/admin/FunnelFlow";
import FunnelFlowList from "./components/admin/FunnelFlowList";
import FunnelFlowEdit from "./components/admin/FunnelFlowEdit";
import Brand from "./components/admin/Brand";
import ProductReview from "./components/admin/ProductReview";
import MasterFrontLayoutV2 from "./layouts/frontend/MasterLayoutV2";
import Bonus from './components/admin/Bonus';
import BonusCategory from './components/admin/BonusCategory';
import TermAndCond from './components/frontend/TermAndCond.js';
import PrivacyPolicy from './components/frontend/PrivacyPolicy.js';
import Citations from './components/frontend/Citations.js';
import { BASE_URL } from './components/Config';
import { CartState } from './context/Context';
import useStore from './store';
import SapphireAddOn from "./components/admin/SapphireAddOn";
import UserComments from "./components/admin/UserComments";

// New look  imports
import FrontPage2 from './components/frontend/FrontPage2';
import DiscountPageV2 from './components/frontend/DiscountPageV2';
import ShopPage2 from "./components/frontend/ShopPage2";
import DomainLogs from "./components/admin/DomainLogs";
import Login2 from "./components/frontend/Login2";
import ContactUs from "./components/frontend/ContactUs";
import OrderStep2 from "./components/frontend/OrderStep2";
import Landing2 from "./components/frontend/Landing2";
import CheckoutPageBeta from "./components/frontend/CheckoutPageBeta";
import Final2 from "./components/frontend/Confirmation2";
import MyAccountInfo2 from "./components/frontend/MyAccountInfo2";
import Dashboard2 from "./components/frontend/Dashboard2";
import ReferralPage2 from "./components/frontend/ReferralPage2";
import BonusPage2 from "./components/frontend/BonusPage2";
import BonusSlug2 from "./components/frontend/BonusSlug2";
import Cart2 from "./components/frontend/Cart2";
import Api from "./components/frontend/Api";
import Faq from "./components/frontend/Faq";
import SingleProduct2 from "./components/frontend/SingleProduct2";
import ForgetPassword2 from "./components/frontend/ForgetPassword2";
import ResetPassword2 from "./components/frontend/ResetPassword2";
import V12 from "./components/frontend/V12";
import V22 from "./components/frontend/V22";
import V1t2 from "./components/frontend/v1t2";
import V2t2 from "./components/frontend/v2t2";
import ChekoutTwo2 from "./components/frontend/CheckoutTwo2";
import FaqCategory from "./components/admin/FaqCategory.js";
import FaqSubCategory from "./components/admin/FaqSubCategory.js";
import FaqQueries from "./components/admin/FaqQueries.js";
import Survey from "./components/admin/Survey.js";
import SurveyFeedback from "./components/admin/SurveyFeedback.js";
import SupportLogs from "./components/admin/SupportLogs.js";
import BlueForrestLead from "./components/CheckoutTemplates/BlueforrestLeadTemplate.js";
import Skincare6pLead from "./components/CheckoutTemplates/Skincare6pLeadTemplate.js";
import Skincare6pUpsell1 from "./components/UpsellTemplate/Skincare6pUpsell1Template.js";
import Skincare6pUpsell2 from "./components/UpsellTemplate/Skincare6pUpsell2Template.js";
import Skincare6pTherapy1 from "./components/LanderTemplate/Skincare6pTherapy1Template.js";
import Skincare6pTherapy2 from "./components/LanderTemplate/Skincare6pTherapy2Template.js";
import DickPillUpsell2 from "./components/UpsellTemplate/DickPillUpsell2Template.js";
import RestrictionPage from "./components/RestrictionPage.js";
import ProspectReport from "./components/admin/ProspectReport.js";
import ProspectReportDetails from "./components/admin/ProspectReportDetails.js";
import ReportByProduct from "./components/admin/ReportByProduct.js";
import CSVUpload from "./components/frontend/CSVUpload.js";
import CustomerService from "./components/Agent/CustomerService.js";
import GuestCheckout from "./components/Agent/GuestCheckout.js";
import TestLander from "./components/LanderTemplate/TestLander.js";
import SkincarebpLead from "./components/CheckoutTemplates/SkincarebpLeadTemplate.js";


import DirectCheckout from "./components/DirectCheckout/DirectCheckout.js";
import DirectNurotech from "./components/DirectCheckout/DirectNurotech.js";
import DirectSynapticCheckout from "./components/DirectCheckout/DirectSynapticCheckout.js";

// New Look ends //

axios.defaults.baseURL =
  BASE_URL + "whc-custom/public/";
axios.defaults.headers.post["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");
axios.defaults.headers.get["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");
axios.defaults.headers.put["Authorization"] =
  "Bearer " + localStorage.getItem("authToken");

let executedOnce = 0;
function App() {
  const isLogin = useStore((state) => state.isLogin);
  const userId = useStore((state) => state.userId);
  const { state: dispatch } = CartState();

  // Prevent Browser Back button
  React.useEffect(() => {
    function disableBack() { 
      window.history.forward() 
  } 
  window.onload = disableBack(); 
  window.onpageshow = function(e) { 
      if (e.persisted) 
          disableBack(); 
  } 
  }, []);

  if (executedOnce === 0 && isLogin) {
    let params = window.location.href.split('/');
    if (params[3] === 'admin') {
      //console.log(params[3]);
    } else {
      if(userId){
        axios.get(`api/cart/${userId}`).then(res => {
          if (res.data.data.length) {
  
            dispatch({
              type: "PREFILL_CART",
              payload: res.data.data,
  
            })
  
          }
        });
      }
    }
    executedOnce++;
  }

  return (
    <div className="App">
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="/acvgummies-mobile-landing" element={<AcvgummiesMobileTemplate />} />
          <Route path="/admin/accessdenied" element={<AccessDenied />} />
          <Route path="/:slug" element={<MasterCheckout />} />
          <Route path="/admin/" element={<Login replace to="/admin/login" />} />
          <Route path="/admin/" element={<MasterLayout />}>
            {/* <Route path="/admin/" element={<Navigate replace to="/admin/dashboard"/>}/> */}
            <Route element={<PrivateRoutes />}>
              <Route path="/admin/dashboard" element={<Dashboard />} />
              <Route path="/admin/profile" element={<Profile />} />
              <Route path="/admin/crminstance" element={<CRMI />} />
              <Route path="/admin/orders" element={<Orders />} />
              <Route path="/admin/addprods" element={<AddProds />} />
              <Route path="/admin/viewprods" element={<ListProducts />} />
              <Route path="/admin/productmapping" element={<AutoProductMap />} />
              <Route path="/admin/users" element={<Adduser />} />
              <Route path="/admin/listusers" element={<Listusers />} />
              <Route path="/admin/category" element={<Category />} />
              <Route path="/admin/brand" element={<Brand />} />
              <Route path="/admin/product_review" element={<ProductReview />} />
              <Route path="/admin/checkoutfunnels" element={<AddCheckoutFunnel />} />
              <Route path="/admin/checkoutfunnelslist" element={<CheckoutFunnelList />} />
              <Route path="/admin/checkoutfunnelsedit" element={<EditCheckoutFunnel />} />
              <Route path="/admin/template" element={<AssignementTemplate />} />
              <Route path="/admin/lander" element={<AssignmentLander />} />
              <Route path="/admin/upsell" element={<AssignmentUpsell />} />
              <Route path="/admin/confirmation" element={<Confirmation />} />
              <Route path="/admin/funnelflow" element={<Funnelflow />} />
              <Route path="/admin/funnelflowlist" element={<FunnelFlowList />} />
              <Route path="/admin/funnelflowedit" element={<FunnelFlowEdit />} />
              <Route path="/admin/bonus-category" element={<BonusCategory />} />
              <Route path="/admin/bonus" element={<Bonus />} />
              <Route path="/admin/sapphire-add-on" element={<SapphireAddOn />} />
              <Route path="/admin/domain-logs" element={<DomainLogs />} />
              <Route path="/admin/add-on-queries" element={<UserComments />} />
              <Route path="/admin/faq-category" element={<FaqCategory />} />
              <Route path="/admin/faq-sub-category" element={<FaqSubCategory />} />
              <Route path="/admin/faq-queries" element={<FaqQueries />} />
              <Route path="/admin/survey-question" element={<Survey />} />
              <Route path="/admin/survey-feedback" element={<SurveyFeedback />} />
              <Route path="/admin/support-logs" element={<SupportLogs />} />
              <Route path="/admin/overview" element={<ProspectReport />} />
              <Route path="/admin/prospect-detail" element={<ProspectReportDetails />} />
              <Route path="/admin/report-by-product" element={<ReportByProduct />} />
            </Route>
          </Route>
          {/* WHC New Look route  */}
          <Route path="/no-access" element={<RestrictionPage/>}></Route>
          <Route path="/" element={<MasterFrontLayoutV2 />}>
            <Route path="/" element={<FrontPage2 />} />
            <Route path="/login" element={<Login2 />} />
            <Route path="/forget-password" element={<ForgetPassword2 />} />
            <Route path="/reset-password" element={<ResetPassword2 />} />
            <Route path="/discount" element={<DiscountPageV2 />} />
            <Route path="/shop/:slug" element={<ShopPage2 />} />
            <Route path="/create-membership" element={<Landing2 />} />
            <Route path="/order-step-1" element={<OrderStep2 />} />
            <Route path="/membership-checkout" element={<CheckoutPageBeta />} />
            <Route path="/confirmation" element={<Final2 />} />
            <Route path="/api" element={<Api />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/checkout-2" element={<ChekoutTwo2 />} />
            <Route path="/cart" element={<Cart2 />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/my-account-info" element={<MyAccountInfo2 />} />
              <Route path="/referral" element={<ReferralPage2 />} />
              <Route path="/bonus" element={<BonusPage2 />} />
              <Route path="/bonus/:slug" element={<BonusSlug2 />} />
            </Route>
            <Route path="/dashboard" element={<Dashboard2 />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/cart" element={<Cart2 />} />
            {/* <Route path="/shop/:slug" element={<ProductByBrand />} /> */}
            <Route path="/product/:slug" element={<SingleProduct2 />} />
            <Route path="/v1" element={<V12 />} />
            <Route path="/v2" element={<V22 />} />
            <Route path="/v1t" element={<V1t2 />} />
            <Route path="/v2t" element={<V2t2 />} />
            <Route path="/term-and-condition" element={<TermAndCond />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/citations" element={<Citations />} />
          </Route>
          {/* <Route path="/skincare6p" element={<Skincare6p />} /> */}
          
          <Route path="/gskin-checkout" element={<Skincare6pLead />} />
          <Route path="/skincarebpLead" element={<SkincarebpLead />} />
          <Route path="/blueforrestLead" element={<BlueForrestLead />} />

          <Route path="/skincare6pUpsell1" element={<Skincare6pUpsell1 />} />
          <Route path="/skincare6pUpsell2" element={<Skincare6pUpsell2 />} />
          <Route path="/skincare6pTherapy1" element={<Skincare6pTherapy1 />} />
          <Route path="/skincare6pTherapy2" element={<Skincare6pTherapy2 />} />
          <Route path="/test-lander" element={<TestLander />} />
          {/* <Route path="/dickPill-home" element={<DickPillHome />} /> */}
          {/* <Route path="/dickPillMob-home" element={<DickPillHomeMob />} /> */}
          {/* <Route path="/dickPillUpsell1" element={<DickPillUpsell1 />} /> */}
          <Route path="/dickPillUpsell2" element={<DickPillUpsell2 />} />
          <Route path="/csv-upload" element={<CSVUpload />} />
          <Route path="/cs" element={<CustomerService />} />
          <Route path="/guest-checkout" element={<GuestCheckout />} />


          {/* <Route path="/DTC-alpha" element={<DirectCheckout />} /> */}
          <Route path="/DTC-neurotech" element={<DirectNurotech />} />
          {/* <Route path="/DTC-synaptic" element={<DirectSynapticCheckout />} /> */}

          {/* WHC New Look route end */}
        </Routes>
    </div>
  );
}

export default App;